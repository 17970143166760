.form-check-inline {
  @apply inline ml-4;
}

.form-check-input[type='radio']:checked {
  background-image: url('../../assets/img/checkedRadioBlack.svg') !important;
}

.input-group-append {
  @apply hidden;
}

/* .form-group {
  margin-bottom: 0px;
} */

.form-control {
  border-radius: 0.375rem !important;
  border: 1px solid rgba(39, 39, 39, 0.24);
}

.date-pick div .input-group input.active:after {
  position: absolute;
  top: 0;
  right: 0.375rem;
  height: 100%;
  border-width: 0;
  z-index: 100;
  border-style: solid;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.5em;
}

.date-pick,
.gender {
  margin-top: 15px;
}
